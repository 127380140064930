import { registerApplication, start } from "single-spa"
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine
} from "single-spa-layout"
import microfrontendLayout from "./microfrontend-layout.html"
import initSPAEventHandler from './scripts/forcepoint-shellui-events-handler';
import initSPAStateHandler from './scripts/forcepoint-shellui-state-handler';
/**
 * Storing routes configuration.
 */
const routes = constructRoutes(microfrontendLayout)

/**
 * Constructing applications.
 */
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name)
  }
})

/**
 * If its localhost then register localui app
 */
if (window.location.hostname === "localhost") {
  registerApplication({
    name: "@forcepoint/platform-localui",
    app: () => System.import("//localhost:9001/forcepoint-platform-localui.js"),
    activeWhen: "/local-login"
  })
}

/**
 * Constructing layout engine
 */
const layoutEngine = constructLayoutEngine({ routes, applications })

initSPAEventHandler();
initSPAStateHandler();

/**
 * Registering application and activate layout engine.
 */
applications.forEach(registerApplication)
layoutEngine.activate()
start()

