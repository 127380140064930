import { registerApplication, navigateToUrl } from "single-spa"
import { isEmpty, isSuperadmin } from "@forcepoint/platform-utilityui"
import { coreStore, AUTO_LOGIN } from "@forcepoint/platform-coreui";

export default function () {

    // This will allow importmap to be passed as xhr requests
    // https://github.com/systemjs/systemjs/blob/main/docs/hooks.md#shouldfetchurl---boolean.
    //
    System.shouldFetch = function () {
        return true
    }

    // Passing header with fetch js requests.
    // https://github.com/systemjs/systemjs/blob/main/src/extras/module-types.js
    System.fetch = function (url, options) {
        const userToken = coreStore.getState()?.userToken
        options.headers = {
            Authorization: `Bearer ${userToken}`
        }
        return fetch(url, options)
    }

    let flag = true // Flag to prevent re-execution

    const isTenantOnboardingRequired = (storeData, app) => {
        // Extract the tenantId list from the store data
        const tenantIdList = storeData?.userInfo?.ext?.tenantId || []

        // Check if the tenantId for this application is available
        const tenantId = tenantIdList.find(
            (tenant) => tenant?.hint === app.applicationCode
        )?.id

        // Check if the app has a tenant onboarding webhook and if the tenantId is missing
        return !isEmpty(app?.webhooks?.tenantOnboarding) && isEmpty(tenantId)
    }


    const getTenantId = (tenantIdList, app) =>
        tenantIdList.find((tenant) => tenant?.hint === app.applicationCode)?.id

    const updateSharedImports = (sharedImports, app) => {
        app.sharedModules.forEach((module) => {
            sharedImports[module.moduleName] =
                module.moduleUrls.prefix + module.moduleUrls.jsPath;
        });
    };

    const registerApplications = (storeData, app, sharedImports) => {
        // Check if the tenant onboarding condition is satisfied
        const tenantOnboardingRequired = isTenantOnboardingRequired(storeData, app);

        // Register the app but conditionally skip loading the JS path if onboarding is required
        app.modules?.forEach((module) => {
            registerApplication({
                name: module.SPAName,
                app: () => {
                    if (tenantOnboardingRequired && !isSuperadmin()) {
                        return System.import("/assets/js/activation-pending.js");
                    }
                    // Normal app registration (load the JS module if no onboarding required)
                    return System.import(
                        module.moduleUrls.prefix + module.moduleUrls.jsPath
                    );
                },
                activeWhen: (location) =>
                    location.pathname.startsWith(module.moduleURLPrefix),
            });
        });
    };
    const getApplicationUrls = (applications) =>
        applications.reduce((urls, app) => {
            if (app.isSPA && app.menus?.length > 0) {
                const appUrls = app.menus.map((menu) => menu.url);
                return [...urls, ...appUrls];
            }
            return urls;
        }, []);

    const resolveDefaultUrl = (defaultUrl, applicationUrls) => {
        const currentPathName = window.location.pathname;

        if (currentPathName === "/") {
            navigateToUrl(defaultUrl || applicationUrls[0]);
        } else {
            const matchingIndex = applicationUrls.findIndex((url) =>
                url.startsWith(currentPathName)
            );
            if (
                matchingIndex !== -1 &&
                (!window.location.hash || window.location.search === "")
            ) {
                navigateToUrl(applicationUrls[matchingIndex]);
            } else if (["/logout", "/401", "/local-login"].includes(currentPathName)) {
                navigateToUrl(applicationUrls[0]);
            }
        }
    };
    const updateDocumentTitle = (storeData) => {
        const localKey = storeData?.activeMenu?.localizationKey
        if (!isEmpty(localKey)) {
            document.title = localKey;
        } else if (storeData?.isSettingsPage) {
            document.title = storeData?.activeMenu?.name || storeData?.appTitle || "";
        } else {
            document.title = "F|ONE";
        }
    };
    /**
     * State subscription.
     */
    coreStore.subscribe(() => {
        const storeData = coreStore.getState()
        if (storeData.applications.length > 0 && flag) {
            flag = false
            const sharedImports = {} // To store shared imports.
            let defaultUrl = null

            // Loop through applications
            for (let app of storeData.applications) {
                if (app.isSPA) {
                    // Shared modules import map
                    updateSharedImports(sharedImports, app);
                    if (app.applicationName === "Insights" && app.menus?.length > 0) {
                        defaultUrl = app.menus[0].url
                    }
                    // Register applications
                    registerApplications(storeData, app, sharedImports);

                }
            }

            // Lazyloading of shared map.
            System.addImportMap({ imports: sharedImports })

            // Collect all application URLs
            const applicationUrls = getApplicationUrls(storeData.applications);

            // Determine the default URL based on current pathname
            resolveDefaultUrl(defaultUrl, applicationUrls);
        }

        // Update document title based on active menu or settings
        updateDocumentTitle(storeData);
    })

    /**
     * Dispatching auto login action
     */
    coreStore.dispatch({ type: AUTO_LOGIN })
}