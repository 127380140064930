import {
    coreStore,
    SET_ACTIVE_APP,
    SET_LOADER_STATUS,
    SET_PAGE_TITLE,
    SET_ACTIVE_MENU,
    SET_SETTINGS_TYPE,
    SET_APP_TITLE
} from "@forcepoint/platform-coreui";
import { isEmpty } from "@forcepoint/platform-utilityui";

export default function () {
    const sessionTabKey = "tabCount"

    const getIntCount = (str) => {
        return str && !isNaN(+str) ? +str : 0
    }

    const onTabClose = () => {
        const str = localStorage.getItem(sessionTabKey)
        const tabCount = getIntCount(str)
        if (tabCount > 0) {
            localStorage.setItem(sessionTabKey, (+tabCount - 1).toString())
        }
    }

    const setTabCount = () => {
        const str = localStorage.getItem(sessionTabKey)
        const tabCount = getIntCount(str)
        localStorage.setItem("isFocused", "true")
        localStorage.setItem(sessionTabKey, (tabCount + 1).toString())
    }

    window.addEventListener("beforeunload", function () {
        onTabClose()
    })

    window.addEventListener("load", function () {
        setTabCount()
    })

    async function startMockService(enable = false) {
        if (process.env.NODE_ENV === "development" && enable) {
            const { worker } = require("../mocks/browser")
            await worker.start()
        }
    }
    /**
     * To enable mockServiceWorker
     * pass true to startMockService()
     */
    startMockService();


    /**
     * Event listener before app change.
     * This is where we intercept the app change and check the onboarding condition.
     */
    window.addEventListener("single-spa:before-app-change", (evt) => {
        coreStore.dispatch({ type: SET_ACTIVE_APP, appName: null })
        coreStore.dispatch({ type: SET_PAGE_TITLE, pageTitle: "" })
        const mountedApp = evt.detail.appsByNewStatus.MOUNTED
        if (!mountedApp.includes("@forcepoint/platform-navigationui")) {
            coreStore.dispatch({ type: SET_LOADER_STATUS, status: true })
            coreStore.dispatch({ type: SET_ACTIVE_APP, appName: mountedApp })
        }
    })

    /**
     * Event listner app change
     */
    window.addEventListener("single-spa:app-change", (evt) => {
        const mountedApp = evt.detail.appsByNewStatus.MOUNTED
        if (!mountedApp.includes("@forcepoint/platform-navigationui")) {
            coreStore.dispatch({ type: SET_LOADER_STATUS, status: false })
            const activeAppCode = coreStore.getState().activeApp?.applicationCode
            const application = coreStore
                .getState()
                .applications?.find((app) => activeAppCode === app?.applicationCode)

            const tenantIdList = coreStore.getState()?.userInfo?.ext?.tenantId
            const tenantId = tenantIdList?.find((x) => x?.hint === activeAppCode)?.id

            const activationPendingDiv = document.getElementById("activation-pending")

            // Check if the onboarding condition is met: tenant onboarding webhook exists and tenantId is missing
            if (
                !isEmpty(application?.webhooks?.tenantOnboarding) && // Ensure the app has a tenant onboarding webhook
                isEmpty(tenantId)
            ) {
                if (activationPendingDiv) {
                    activationPendingDiv.style.display = "block"
                }
            } else {
                if (activationPendingDiv) {
                    activationPendingDiv.style.display = "none"
                }
            }
        }
    })

    /**
     * Routing event SPA
     */
    window.addEventListener("single-spa:routing-event", (evt) => {
        coreStore.dispatch({ type: SET_ACTIVE_MENU, url: window.location.pathname })
        if (window.location.pathname.includes("settings")) {
            coreStore.dispatch({ type: SET_SETTINGS_TYPE, status: true })
            coreStore.dispatch({ type: SET_APP_TITLE, appTitle: "Settings" })
            coreStore.dispatch({ type: SET_ACTIVE_MENU, url: window.location.pathname })
        } else {
            coreStore.dispatch({ type: SET_SETTINGS_TYPE, status: false })
        }
        if (evt.currentTarget.location.pathname.includes("coming-soon")) {
            coreStore.dispatch({ type: SET_LOADER_STATUS, status: false })
        }
    })

}